export const initReferrer = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const xIntentId = urlParam.get("xpay_intent_id");

  if (sessionStorage.getItem("xIntentId") !== xIntentId) {
    const referrer = document.referrer;
    sessionStorage.setItem("referrer", referrer);
  }
  sessionStorage.setItem("xIntentId", xIntentId);
};
