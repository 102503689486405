import { SentryRoutes } from "./v3/utils/initSentry";
import { BrowserRouter, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { GlobalProvider } from "./v3/contexts/GlobalContext";
import GlobalErrorBoundary from "./v3/utils/GlobalErrorBoundary";
import PaymentConfirmation from "./v3/components/OneTimePayment/PaymentLinks/PaymentConfirmation/PaymentConfirmationScreen";
import PaymentExpired from "./v3/components/OneTimePayment/PaymentLinks/PaymentExpired";
import PaymentCompleted from "./v3/components/OneTimePayment/PaymentLinks/PaymentCompleted";

import FeatureFlag from "./FeatureFlag";

import AntDesignContext from "./v3/contexts/AntDesignContext";
import SubsCompleted from "./v3/components/Subscription/PaymentLinks/SubsCompleted";
import SubsExpired from "./v3/components/Subscription/PaymentLinks/SubsExpired";
import CustomerChooseWhatToPayV3 from "./v3/CustomerChooseWhatToPay";
import OneTimePaymentsV3 from "./v3/OneTimePayments";
import SubscriptonsV3 from "./v3/Subscriptions";
import AMAConfirmtaionV3 from "./v3/components/CustomerChooseWhatToPay/AMA/AMAConfirmtaion";
import ConnectivityTracker from "./v3/utils/ConnectivityTracker";
import MensenConfirmation from "./v3/components/CustomerChooseWhatToPay/Mensen/MensenConfirmation";
import CCWTPMensen from "./v3/CCWTPMensen";

const App = () => {
  return (
    <GlobalProvider>
      <GlobalErrorBoundary>
        <AntDesignContext>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/" element={<FeatureFlag />} />

              {/* V3 path */}
              <Route path="/v3" element={<OneTimePaymentsV3 />} />
              <Route path="/v3/subscription" element={<SubscriptonsV3 />} />

              {/* AMA */}
              <Route path="/v2/AMA" element={<CustomerChooseWhatToPayV3 />} />
              <Route
                path="/v2/AMA/success/:xIntentId"
                element={<AMAConfirmtaionV3 />}
              />

              {/* Mensen */}
              <Route
                path="/v2/mensen"
                element={<CCWTPMensen />}
              />
              <Route
                path="/v2/mensen/success/:xIntentId"
                element={<MensenConfirmation />}
              />

              {/* Payment Links Routes  */}
              <Route path="/confirmation" element={<PaymentConfirmation />} />
              <Route
                path="/confirmation/subscription"
                element={<PaymentConfirmation />}
              />
              <Route path="/links/completed" element={<PaymentCompleted />} />
              <Route path="/links/expired" element={<PaymentExpired />} />

              {/* Subscription Links Routes  */}
              <Route path="/subs/links/completed" element={<SubsCompleted />} />
              <Route path="/subs/links/expired" element={<SubsExpired />} />
            </SentryRoutes>
          </BrowserRouter>
        </AntDesignContext>
        <ConnectivityTracker />
        <Toaster />
      </GlobalErrorBoundary>
    </GlobalProvider>
  );
};

export default App;
