/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Select, Modal, Input, Empty } from "antd";
import { FaChevronDown } from "react-icons/fa6";
import { EVENT_NAMES } from "../../constants/eventNames";
import { sendEvent } from "../../utils/EventStreaming/eventService";

const CustomCurrencyField = ({
  currency,
  setCurrency,
  currenciesOptionsList,
  getCalculatedAmount,
  getPaymentMethod,
  setIntentDetails,
  setPaymentError,
  setIsPaymentBtnDisabled,
  country,
  xIntentId,
}) => {
  const { Option } = Select;
  const selectedOptionRef = useRef(null);
  const componentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState();
  const [label, setLabel] = useState(
    currenciesOptionsList.find((option) => option.value === currency)?.label
  );
  const [filteredOptions, setFilteredOptions] = useState(currenciesOptionsList);

  useEffect(() => {
    setFilteredOptions(currenciesOptionsList);
  }, [currenciesOptionsList]);

  useEffect(() => {
    setLabel(
      currenciesOptionsList.find((option) => option.value === currency)?.label
    );
  }, [currency, currenciesOptionsList]);

  const onChange = (value, options) => {
    setCurrency(value);
    setLabel(options.label);
    setIntentDetails(null);
    setPaymentError(null);
    setIsPaymentBtnDisabled(true);
    getCalculatedAmount({ value });
    getPaymentMethod(value, { country: country });
    sendEvent(xIntentId, EVENT_NAMES.CURRENCY_SELECTOR_SELECT, {
      currency: value,
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      if (selectedOptionRef.current) {
        selectedOptionRef.current.scrollIntoView({
          behavior: "instant",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchText("");
    setFilteredOptions(currenciesOptionsList);
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    const value = e.target.value.toLowerCase();

    const filtered = currenciesOptionsList.filter(
      (option) =>
        option.label.toLowerCase().includes(value) ||
        option.value.toLowerCase().includes(value) ||
        option.searchValue.toLowerCase().includes(value)
    );

    setFilteredOptions(filtered);
  };

  useEffect(() => {
    const handleClick = () =>
      sendEvent(xIntentId, EVENT_NAMES.CURRENCY_SELECTOR_OPEN, {});
    componentRef.current?.addEventListener("click", handleClick);
    return () =>
      componentRef.current?.removeEventListener("click", handleClick);
  }, [componentRef]);

  return (
    <div ref={componentRef}>
      {currenciesOptionsList.length > 1 && (
        <>
          <div className="hidden sm:flex">
            <Select
              options={currenciesOptionsList}
              size={"large"}
              onChange={onChange}
              value={currency}
              popupMatchSelectWidth={130}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase()) ||
                option.value.toLowerCase().includes(input.toLowerCase()) ||
                option.searchValue.toLowerCase().includes(input.toLowerCase())
              }
              notFoundContent={<Empty description="No currency found" />}
            >
              {currenciesOptionsList.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  searchValue={option.searchValue}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
          <div
            className="p-2 border border-[#d9d9d9] flex items-center justify-center text-sm rounded-md gap-2 sm:hidden font-sans"
            onClick={showModal}
          >
            {label} <FaChevronDown className="opacity-20" />
          </div>
          <Modal
            title="Select Currency"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={225}
          >
            <Input
              placeholder="Search currency"
              allowClear
              onChange={onSearchChange}
              value={searchText}
              className="mt-2"
              autoFocus={false}
              size="large"
            />
            <div className="max-h-[30vh] overflow-y-auto pb-2 pr-2 font-sans mt-4">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  ref={currency === option.value ? selectedOptionRef : null}
                  className={`flex items-center justify-between p-2 rounded-md ${
                    currency === option.value ? "bg-[#e6f4ff]" : ""
                  }`}
                  onClick={() => {
                    onChange(option.value, option);
                    handleCancel();
                  }}
                >
                  <span className="font-sans ">{option.label}</span>
                </div>
              ))}
              {filteredOptions.length === 0 && (
                <Empty description="No currency found." />
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CustomCurrencyField;
