/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "../UI/Loader";
import { useEffect, useState } from "react";
import completedAnimation from "../../assets/Animations/paymentCompleted.json";
import { ImSpinner2 } from "react-icons/im";
const TerminalState = ({ loadingMain }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!loadingMain) {
      const messageTimeout = setTimeout(() => {
        if (document.referrer) setShowMessage(true);
      }, 1000);

      const redirectTimeout = setTimeout(() => {
        if (document.referrer) {
          window.history.forward();
        }
      }, 3000);

      return () => {
        clearTimeout(messageTimeout);
        clearTimeout(redirectTimeout);
      };
    }
  }, [loadingMain]);

  return (
    <>
      {!loadingMain && (
        <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
          <Loader
            json={completedAnimation}
            width="500px"
            height="250px"
            loop={false}
            keepLastFrame={true}
            mobileHeight="250px"
            mobileWidth="500px"
          />
          <div className="mb-8 font-sans text-xl">
            Your Payment is Completed!
          </div>
          {
            <p
              className={`${
                showMessage ? "flex" : "hidden"
              } text-gray-500 text-sm justify-center items-center gap-2 transition-all duration-500 ease-in-out font-sans`}
            >
              Redirecting to your website...{" "}
              <ImSpinner2 className="animate-spin" />
            </p>
          }
        </div>
      )}
    </>
  );
};

export default TerminalState;
