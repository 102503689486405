import { captureException } from "@sentry/react";
import axios from "../../axios";
import { createEvent } from "./eventContract";

export const sendEvent = async (intentId, eventType, eventData = {}) => {
  const event = createEvent(intentId, eventType, eventData);
  try {
    await axios.post("/ui-event/ingest", event);
    console.log("[📣Event]:", event.eventType);
  } catch (error) {
    captureException(error);
  }
};
