/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InputField from "../../UI/FormFields/InputField";
import { fieldNames } from "../../../constants/fieldNames";
import { Country } from "country-state-city";
import { forEach } from "lodash";
import SelectField from "../../UI/FormFields/SelectField";
import { Form, Button } from "antd";
import { IoCloseOutline } from "react-icons/io5";
import usePostIngestBillingAddress from "../../../hooks/OneTimePayment/usePostIngestBillingAddress";
import { toast } from "react-hot-toast";
import WarningToast from "../../UI/Toasts/WarningToast";

const YourDetails = ({ setOpen, billingDetails, setBillingDetails }) => {
  const [countries, setCountries] = useState([]);
  const { loading, postIngestBillingAddress } = usePostIngestBillingAddress();

  useEffect(() => {
    const countryData = Country.getAllCountries();
    let countryOptions = [];
    forEach(countryData, (country) => {
      countryOptions.push({
        label: country.flag + "  " + country.name,
        value: country.isoCode + "-" + country.name,
      });
    });

    setCountries(countryOptions);
  }, []);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await postIngestBillingAddress(values, true);
    setOpen(false);
    setBillingDetails(values);
  };

  const onClose = () => {
    if (
      billingDetails?.name &&
      billingDetails?.email &&
      billingDetails?.country
    ) {
      setOpen(false);
    } else {
      toast.custom(
        (t) => (
          <WarningToast
            t={t}
            message="Please fill all the details. We collect this information to help combat fraud, and to keep your payment secure."
          />
        ),
        {
          id: "userDetails",
        }
      );
    }
  };

  return (
    <div className="flex flex-col gap-5 font-sans sm:py-3 sm:px-6 z-[1000]">
      <div className="flex items-center justify-between gap-2 text-xl font-medium">
        Billing Details
        <IoCloseOutline
          size={20}
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>
      <Form
        form={form}
        className="font-sans"
        initialValues={billingDetails}
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
        onFinish={onFinish}
      >
        <InputField
          name={fieldNames.name}
          label="Name"
          required
          placeholder={"Enter your name"}
        />
        <InputField
          name={fieldNames.email}
          label="Email"
          required
          placeholder={"Enter your email"}
        />
        <div className="grid grid-cols-2 gap-3">
          <InputField
            name={fieldNames.pincode}
            label="ZIP/Postcode"
            required={false}
            placeholder={"Enter your postal code"}
          />
          <SelectField
            name={fieldNames.country}
            label="Country"
            placeholder="Select your country"
            required
            options={countries}
          />
        </div>
        <Form.Item noStyle>
          <Button
            size="large"
            className="font-sans font-semibold"
            block
            loading={loading}
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default YourDetails;
