import { captureException } from "@sentry/react";
import { toast } from "react-hot-toast";
import ErrorToast from "../components/UI/Toasts/ErrorToast";

export const ErrorHandler = async (error, setStatus) => {
  captureException(error);
  setStatus("ERROR");

  if (error?.response?.data?.errorCode === "terminal_state") {
    setStatus("TERMINAL_STATE");
    return;
  }

  if (error.code === "ECONNABORTED") {
    try {
      await fetch("https://www.google.com", {
        method: "HEAD",
        mode: "no-cors",
      });
      console.log("Internet access available || Gooogle.com reached");
    } catch (error) {
      console.log("No internet access || Gooogle.com not reached");
    }
  }

  if (process.env.REACT_APP_XPAY_ENV !== "prod") {
    toast.custom(
      (t) => (
        <ErrorToast
          t={t}
          message={
            error?.response?.data?.errorDescription ||
            error.message ||
            "Something went wrong"
          }
        />
      ),
      {
        id: "errorToast",
      }
    );
    console.error(error);
  } else {
    const status = error?.status || error?.response?.status;
    if (status >= 400 && status < 500) {
      toast.custom(
        (t) => (
          <ErrorToast
            t={t}
            message={error?.response?.data?.errorDescription || error.message}
          />
        ),
        {
          id: "errorToast",
        }
      );
    } else if (status >= 500 && status < 600) {
      toast.custom(
        (t) => <ErrorToast t={t} message={"Something went wrong"} />,
        {
          id: "errorToast",
        }
      );
    } else {
      toast.custom((t) => <ErrorToast t={t} message={"Unexpected error"} />, {
        id: "errorToast",
      });
    }
  }
};
