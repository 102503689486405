export const EVENT_NAMES = {
  CHECKOUT_OPEN: "CHECKOUT_OPEN",
  BILLING_DETAILS_SUBMIT: "BILLING_DETAILS_SUBMIT",
  CURRENCY_SELECTOR_OPEN: "CURRENCY_SELECTOR_OPEN",
  CURRENCY_SELECTOR_SELECT: "CURRENCY_SELECTOR_SELECT",
  PAYMENT_METHOD_SELECTED: "PAYMENT_METHOD_SELECTED",
  PAY_BUTTON_CLICKED: "PAY_BUTTON_CLICKED",
  INTENT_STATUS_SUCCESS: "INTENT_STATUS_SUCCESS",
  INTENT_STATUS_FAILED: "INTENT_STATUS_FAILED",
};
