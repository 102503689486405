import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import usePaymentConfirmation from "./usePaymentConfirmation";
import { useState } from "react";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/eventNames";

const useTriggerPayment = () => {
  const {
    intentDetails,
    globalIntentData,
    xIntentId,
    stripePromise,
    stripeElement,
    setPaymentError,
    setStatus,
    currency,
    amount,
    paymentMethod,
    form,
    paymentRequest,
    addressElement,
    billingDetails,
  } = useOneTimePaymentsContext();
  const { collectStripeCallbackData } = usePaymentConfirmation();

  const ERROR_MESSAGE =
    "Your payment was declined by the payment method provider.";

  const [payBtnLoading, setPayBtnLoading] = useState(false);

  const scrollToContainerTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const element = document.getElementById("payment");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
        marginTop: "-100px",
      });
    }
  };

  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("callbackUrl", intentDetails.callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", intentDetails.pgPublicKey);
    returnUrl.searchParams.set("amount", amount);

    return returnUrl.href;
  };

  const triggerPayment = async () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        await chargeCard();
        break;
      case "STRIPE":
        await stripePay();
        break;
      default:
        break;
    }
  };

  const chargeCard = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        const cardValidity = globalIntentData?.cardValidity?.split(" / ");
        const cardNumber = globalIntentData?.cardNumber?.replace(/\s/g, "");
        const cardDetails = {
          cardNumber: cardNumber,
          cardHolderName: globalIntentData?.cardName,
          expiryMonth: cardValidity[0],
          expiryYear: cardValidity[1],
          cardCode: globalIntentData?.cardSecurityCode,
        };
        setPayBtnLoading(true);
        try {
          const { data } = await axios.post(`/payments/charge-card`, {
            xIntentId: xIntentId,
            cardDetails: cardDetails,
          });

          if (data?.status === "SUCCESS") {
            setStatus("SUCCESS");
          } else {
            setPaymentError(data?.errorMessage);
          }
        } catch (error) {
          ErrorHandler(error, setStatus);
        } finally {
          setPayBtnLoading(false);
        }
      })
      .catch((error) => {
        setPaymentError("Please fill in all required fields.");
      });
  };

  const walletPayments = async () => {
    if (!paymentRequest) {
      return;
    }

    paymentRequest.once("paymentmethod", async (event) => {
      const { error, paymentIntent } = await stripePromise.confirmCardPayment(
        intentDetails?.clientSecret,
        { payment_method: event.paymentMethod.id }
      );

      await collectStripeCallbackData();

      if (error) {
        event.complete("fail");
        scrollToContainerTop();
        if (error.type === "card_error" || error.type === "validation_error") {
          setPaymentError(error.message);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: error.message,
          });
        } else {
          setPaymentError(ERROR_MESSAGE);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: ERROR_MESSAGE,
          });
        }
      } else if (paymentIntent) {
        event.complete("success");
        if (paymentIntent.status === "succeeded") {
          setStatus("SUCCESS");
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
        } else {
          scrollToContainerTop();
          setPaymentError(ERROR_MESSAGE);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: ERROR_MESSAGE,
          });
        }
      }
    });

    paymentRequest.on("cancel", function () {
      setPaymentError(ERROR_MESSAGE);
      sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
        errorMessage: ERROR_MESSAGE,
      });
    });

    paymentRequest.show();
  };

  const normalStripeFlow = async () => {
    const elements = stripeElement;

    if (!stripePromise || !stripeElement) {
      return;
    }

    const addressDetails = await addressElement?.getValue();

    setPayBtnLoading(true);
    const returnUrl = generateReturnUrl();

    const { error, paymentIntent } = await stripePromise.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
        ...(paymentMethod === "AFTERPAY" && {
          payment_method_data: {
            billing_details: {
              name: addressDetails?.value?.name || billingDetails?.name,
            },
          },
        }),
      },
      redirect: "if_required",
    });

    await collectStripeCallbackData();

    if (error) {
      scrollToContainerTop();
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: error.message,
        });
      } else {
        setPaymentError(ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: ERROR_MESSAGE,
        });
      }
    } else if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        setStatus("SUCCESS");
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
      } else {
        scrollToContainerTop();
        setPaymentError(ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: ERROR_MESSAGE,
        });
      }
    }
  };

  const stripePay = async () => {
    if (paymentMethod === "GOOGLE_PAY" || paymentMethod === "APPLE_PAY") {
      await walletPayments();
    } else {
      await normalStripeFlow();
    }

    setPayBtnLoading(false);
  };

  return { triggerPayment, payBtnLoading };
};

export default useTriggerPayment;
