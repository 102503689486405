import React from "react";
import GridBackground from "./components/UI/GridBackground";
import Container from "./components/Subscription/Container";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";
import { posthog } from "posthog-js";

const SubscriptionsV3 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionId = urlParams.get("subscription_id");

  posthog.people.set({
    xPayId: subscriptionId,
  });

  return (
    <div>
      <GridBackground>
        <SubscriptionProvider>
          <Container />
        </SubscriptionProvider>
      </GridBackground>
    </div>
  );
};

export default SubscriptionsV3;
