/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useEffect, useState } from "react";
import useGetPaymentMethod from "../../hooks/OneTimePayment/usePaymentMethod";
import usePostIngestBillingAddress from "../../hooks/OneTimePayment/usePostIngestBillingAddress";
import { loadStripe } from "@stripe/stripe-js";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/eventNames";
const usePrefetchCurrencies = () => {
  const {
    xIntentId,
    setCurrency,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setAmount,
    setIntentDetails,
    intentDetails,
    setPaymentMethod,
    setCallbackUrl,
    setPaymentError,
    billingDetails,
    setBillingDetails,
    setCountry,
  } = useOneTimePaymentsContext();

  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);

  const { getPaymentMethod, paymentMethodList } = useGetPaymentMethod();
  const { postIngestBillingAddress } = usePostIngestBillingAddress();

  const urlParam = new URLSearchParams(window.location.search);
  const clientSecret = urlParam.get("payment_intent_client_secret");

  // Helpers
  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  const getCurrencyCountryName = (currency) => {
    const regionName = new Intl.DisplayNames(["en"], { type: "region" });
    const regionCode = currency.slice(0, 2).toUpperCase();
    const countryName = regionName.of(regionCode);

    return countryName || "";
  };

  const getCurrencyName = (currency) => {
    const currencyNameFormatter = new Intl.DisplayNames(["en"], {
      type: "currency",
    });
    return currencyNameFormatter.of(currency) || "";
  };

  const clearUrlParams = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.delete("currency");
    searchParams.delete("amount");
    searchParams.delete("paymentMethod");
    searchParams.delete("payment_intent");
    searchParams.delete("pgPublicKey");
    searchParams.delete("payment_intent_client_secret");
    searchParams.delete("redirect_status");
    searchParams.delete("callbackUrl");
    url.search = searchParams.toString();
    window.history.replaceState({}, "", url.toString());
  };

  // Setters
  const setDropdownCurrencies = (data) => {
    let currenciesOptionsList = [];

    currenciesOptionsList.push({
      label: getCurrencySymbol(data.primary.currency),
      value: data.primary.currency,
      searchValue:
        getCurrencyCountryName(data.primary.currency) +
        " - " +
        getCurrencyName(data.primary.currency) +
        " ",
    });

    data.others.forEach((element) => {
      currenciesOptionsList.push({
        label: getCurrencySymbol(element),
        value: element,
        searchValue:
          getCurrencyCountryName(element) +
          " - " +
          getCurrencyName(element) +
          " ",
      });
    });

    setCurrenciesOptionsList(currenciesOptionsList);
  };

  const setData = (data) => {
    if (!clientSecret) {
      setAmount(data?.primary?.amount);
      setCurrency(data?.primary?.currency);
    }
    setMerchantName(data?.merchantName);
    setMode(data?.mode);
    setCallbackUrl(data?.callbackUrl);
    setCountry(data?.country);
    setDropdownCurrencies(data);
  };

  // Handlers
  const handleAsyncPM = async () => {
    if (clientSecret) {
      const stripe = await loadStripe(urlParam.get("pgPublicKey"));
      const { paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );

      setCallbackUrl(urlParam.get("callbackUrl"));
      setPaymentMethod(urlParam.get("paymentMethod"));
      setCurrency(urlParam.get("currency"));
      setAmount(Number(urlParam.get("amount")));
      setIntentDetails({
        ...intentDetails,
        pg: "STRIPE",
        clientSecret: clientSecret,
        pgPublicKey: urlParam.get("pgPublicKey"),
        xIntentId: urlParam.get("xpay_intent_id"),
        callbackUrl: urlParam.get("callbackUrl"),
      });

      clearUrlParams();

      if (paymentIntent.status === "succeeded") {
        setStatus("SUCCESS");
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
        return true;
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage:
            "Your payment was declined by the payment method provider.",
        });
      }
    }
  };

  const handleBillingDetails = async (data) => {
    setBillingDetails({
      ...billingDetails,
      [fieldNames.name]: data?.name,
      [fieldNames.email]: data?.email,
      [fieldNames.pincode]: data?.pinCode,
      [fieldNames.country]: data?.country
        ? data?.country + "-" + Country.getCountryByCode(data?.country)?.name
        : null,
    });

    if (data?.name && data?.email && data?.pinCode && data?.country) {
      const values = {
        [fieldNames.name]: data?.name,
        [fieldNames.email]: data?.email,
        [fieldNames.pincode]: data?.pinCode,
        [fieldNames.country]: data?.country,
      };

      await postIngestBillingAddress(values, false);
    }
  };

  // Main
  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        const isSuccess = await handleAsyncPM();
        if (isSuccess) return;
        const { data } = await axios.post(
          `/payments/v2/prefetchCurrencies/${xIntentId}`
        );
        setData(data);
        await handleBillingDetails(data);
        await getPaymentMethod(
          urlParam.get("currency") || data?.primary?.currency,
          data
        );

        if (!clientSecret) {
          sendEvent(xIntentId, EVENT_NAMES.CHECKOUT_OPEN, {});
        }
        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };

    getPrefetchCurrencies();
  }, []);

  return {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getPaymentMethod,
    paymentMethodList,
  };
};

export default usePrefetchCurrencies;
