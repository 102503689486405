import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { toast } from "react-hot-toast";
import SuccessToast from "./../../components/UI/Toasts/SuccessToast";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/eventNames";

const usePaymentMethod = () => {
  const { xIntentId, setStatus } = useOneTimePaymentsContext();

  const [loading, setLoading] = useState(false);

  const postIngestBillingAddress = async (values, showToast) => {
    setLoading(true);
    try {
      await axios.post(`/payments/ingest-billing-address`, {
        xIntentId: xIntentId,
        billingDetails: {
          name: values?.name,
          email: values?.email,
          customerAddress: {
            postalCode: values?.pincode,
            country: values?.country.split("-")[0],
          },
        },
      });

      if (showToast) {
        toast.custom(
          (t) => (
            <SuccessToast
              t={t}
              message="Billing details updated successfully!"
            />
          ),
          {
            id: "userDetails",
          }
        );

        sendEvent(xIntentId, EVENT_NAMES.BILLING_DETAILS_SUBMIT, {
          billingDetails: {
            name: values?.name,
            email: values?.email,
            customerAddress: {
              postalCode: values?.pincode,
              country: values?.country.split("-")[0],
            },
          },
        });
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { loading, postIngestBillingAddress };
};

export default usePaymentMethod;
