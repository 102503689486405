import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import initSentry from "./v3/utils/initSentry";
import { PostHogProvider } from "posthog-js/react";
import config from "./config";
import { initReferrer } from "./v3/utils/initReferrer";

const options = {
  api_host: config.postHog_host,
};

initSentry(); // Initialize Sentry for error monitoring
initReferrer(); // Initialize referrer

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PostHogProvider apiKey={config.postHog_key} options={options}>
    <App />
  </PostHogProvider>
);
